$pink: #FFDFDB;
$purple: #3F3763;

$layout-breakpoint-large: 1920px;
$layout-breakpoint-medium: 1440px;
$layout-breakpoint-small: 960px;
$layout-breakpoint-xsmall: 480px;

$background: #f0eff1;
$white: #fff;
$black: #24242a;
$gray: #B3B2B8;

.display-none{
  display: none !important;
}

body {
  background: #FFDFDB;
  color: #3F3763;
  max-width: 100%;
  font-family: "Jost", sans-serif;
  max-width: 100%;
    
  
}
//typography//
@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&display=swap');

.dancing{
  font-family: "Dancing Script", cursive;
  font-size: 30px;
  @media (max-width: $layout-breakpoint-xsmall) {
      font-size: 12px;
    }

}





.loader {
  .loader-inner {
    .image-block {
      position: absolute;
      transform-origin: center;
      display: flex;
      align-items: center;
      justify-content: center;

      &.image-1 {
        width: 400px;
        left: 16%;
        bottom: 14%;

        @media (max-width: $layout-breakpoint-xsmall) {
          left: 4%;
          bottom: 28%;
        }
      }

      &.image-3 {
        width: 300px;
        right: 12%;
        top: 8%;

        @media (max-width: $layout-breakpoint-xsmall) {
          top: 16%;
          right: 4%;
        }
      }

      &.image-4 {
        max-width: 400px;
        width: 40%;
        right: 20%;
        bottom: 10%;

        @media (max-width: $layout-breakpoint-xsmall) {
          right: 6%;
          bottom: 32%;
        }
      }

      &.image-5 {
        width: 280px;
        left: 14%;
        top: 12%;

        @media (max-width: $layout-breakpoint-xsmall) {
          left: 6%;
          top: 18%;
        }
      }

      img {
        width: 100%;
        object-fit: fill;
      }
    }
  }
}

///Header///
.header {
  font-size: 1.8rem;
  height: 156px;
  display: flex;
  position: absolute;
  justify-content: end;
  padding: 50px 50px;
  width: 100%;
  @media (max-width: $layout-breakpoint-medium) {
    font-size: 1.6rem;
  }

  @media (max-width: $layout-breakpoint-xsmall) {
    padding: 12px 12px;
    height: 96px;
    font-size: 1.2rem;
  }

  .header-inner {
    width: 100%;
    .contact {
      cursor: pointer;
      float: right;
      @media (max-width: $layout-breakpoint-medium) {
        flex: 1;
      }
    }
  }
}

.MuiDrawer-paperAnchorRight{
  background-color: $pink !important;
    padding: 100px 80px;
  position: fixed !important;
    @media (max-width: $layout-breakpoint-xsmall) {
      padding: 100px 10px;
    }
  
    @media (max-width: $layout-breakpoint-small) {
      padding: 100px 10px;
    }
  
    .close-button {
      border-radius: 50%;
      width: 1px;
      padding: 0;
      margin: 0;
    }
  
    .menu-title {
      font-size: 45px;
      padding: 20px 0;
      cursor: pointer;
  
      @media (max-width: $layout-breakpoint-xsmall) {
        font-size: 20px;
      }
  
      @media (max-width: $layout-breakpoint-small) {
        font-size: 20px;
      }
  
      .nav {
        text-decoration: none;
        color: $purple !important;
  
        &:hover {
          text-decoration: underline;
        }
      }
    }
}

.MuiDrawer-paperAnchorRight {
  background-color: $pink !important;
    padding: 100px 80px;
    position: fixed !important;
    @media (max-width: $layout-breakpoint-xsmall) {
        padding: 100px 10px;
      }
            @media (max-width: $layout-breakpoint-small) {
              padding: 100px 10px;
            }
    
    .close-button{
      border-radius: 50%;
      width: 1px;
      padding: 0;
      margin: 0;
    }
    .menu-title{
      font-size: 45px;
      padding: 20px 0;
      cursor: pointer;
      @media (max-width: $layout-breakpoint-xsmall) {
          font-size: 20px;
        }
                @media (max-width: $layout-breakpoint-small) {
                  font-size: 20px;
                }
      
      .nav{
        text-decoration: none;
        color: $purple !important;
        &:hover{
          text-decoration: underline;
        }
      }
    }
}

///Banner///
.banner {
  position: static;
  .banner-row {
    overflow: hidden;
    display: flex;
    align-items: center;
    padding: 0 100px;
    @media (max-width: $layout-breakpoint-small) {
      padding: 0 20px;
      display: block;
      }

    &.center {
      justify-content: center;
      text-align: center;

      .row-letter {
        left: 0;
      }
    }

    &.right {
      justify-content: flex-end;
    }

    .row-title,
    .row-letter {
      font-size: 100px;
      padding-top: 50px;
      font-weight: 500;
      position: relative;
      display: inline-block;
      white-space: nowrap;
      @media (max-width: $layout-breakpoint-xsmall) {
        padding-top: 10px;
        font-size: 50px;
        letter-spacing: 0;
      }
    }

    .row-col {
      width: 100%;
      display: flex;
      @media (max-width: $layout-breakpoint-xsmall) {
          width: 70%;
        }
      @media (max-width: $layout-breakpoint-medium) {
        width: 70%;
      }

      @media (max-width: 1240px) {
        &:last-child {
          display: none;
        }
      }

      @media (max-width: $layout-breakpoint-xsmall) {
        width: 100%;
        justify-content: flex-start;
      }
    }

    .scroll {
      height: 160px;
      width: 160px;
      border-radius: 100%;
      background: $white;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      left: 160px;

      @media (max-width: $layout-breakpoint-medium) {
        left: 60px;
      }

      @media (max-width: 1240px) {
        display: none;
      }
      span {
        font-size: 18px;
        font-weight: 600;
        margin: 4px 0;

        @media (max-width: $layout-breakpoint-xsmall) {
          font-size: 10px;
          margin: 2px 0;
        }
      }
    }
  }
}

.try{
  display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  img{
    width: 250px;
    display: flex;
    position: absolute;
    z-index: 3;

  }
}

.transition-image {
  top: 0;
  align-items: center;
  justify-content: center;
  img {
    width: 250px;
    display: flex;
  }
  &.final {
    top: 0;
    @media (max-width: $layout-breakpoint-xsmall) {
      top: 0;
    }
    img {
      display: fixed;
      top: 0;
      width: 100%;
      object-fit: contain;
      object-position: top;
    }
  }
}

.progress-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 10px;
  z-index: 10;
  transform-origin: 0%;
  background-color: $purple;
}

.about{
  padding: 150px 200px;
  text-align: justify;
  @media (max-width: $layout-breakpoint-xsmall) {
      padding: 10px 10px;
    }
    @media (max-width: $layout-breakpoint-small) {
      padding: 40px 20px;
    }

    .about-body{
      padding-left: 100px;
      @media (max-width: $layout-breakpoint-xsmall) {
          padding: 0;
        }
                 @media (max-width: $layout-breakpoint-small) {
                   padding: 0px;
                 }
      .title {
          font-size: 25px;
          font-weight: 800;
          font-family: "Jost", sans-serif;
          padding-top: 130px;
          padding-bottom: 20px;
          @media (max-width: $layout-breakpoint-xsmall) {
              font-size: 12px;
              padding-top: 10px;
              overflow-y: hidden;
            }
                        @media (max-width: $layout-breakpoint-small) {
                          padding-top: 20px;
                        }
        }
        .subtitle-bold {
          font-size: 33px;
          font-weight: 600;
          font-family: "Jost", sans-serif;
          padding-top: 30px;
          text-transform: uppercase;
          overflow-y: hidden;
          @media (max-width: $layout-breakpoint-xsmall) {
              font-size: 12px;
              padding-top: 10px;
              
            }
        }
      
        .subtitle {
          font-size: 23px;
          font-weight: 300;
          font-family: "Jost", sans-serif;
          overflow-y: hidden;
          @media (max-width: $layout-breakpoint-xsmall) {
              font-size: 12px;
              
            }
        }

    }
  


  .thumbnail{
    overflow: hidden;
      position: relative;
      overflow: hidden;
      text-align: center;
      &.right{
        text-align: right;
        border-radius: 200px 0px 200px 200px;
        @media (max-width: $layout-breakpoint-xsmall) {
            border-radius: 20px;
            text-align: center;
          }
      }
            &.border-1 {
              border-radius: 200px 200px 200px 0;
              @media (max-width: $layout-breakpoint-xsmall) {
                  border-radius: 20px;
                }
            }

            &.border-3 {
                border-radius: 0px 200px 200px 200px;
                @media (max-width: $layout-breakpoint-xsmall) {
                    border-radius: 20px;
                  }
            }

      .image1 {
          border-radius: 200px 200px 200px 0;
          width: 80%;
          @media (max-width: $layout-breakpoint-xsmall) {
              border-radius: 10px;
              width: 70%;
            }
        }
      .image2 {
          border-radius: 200px 0px 200px 200px;
          width: 80%;
          @media (max-width: $layout-breakpoint-xsmall) {
              border-radius: 10px;
              width: 70%;
            }
      }
      .image3 {
          border-radius: 0px 200px 200px 200px;
          width: 80%;
          @media (max-width: $layout-breakpoint-xsmall) {
              border-radius: 10px;
              width: 70%;
            }
      }
  }
    
}



.product {
  padding: 150px 200px;
  text-align: center;
    @media (max-width: $layout-breakpoint-xsmall) {
      padding: 40px 20px;
    }
    @media (max-width: $layout-breakpoint-small) {
      padding: 40px 20px;
    }
  .title {
    font-size: 50px;
    font-weight: 800;
    font-family: "Jost", sans-serif;
    padding-bottom: 20px;
    @media (max-width: $layout-breakpoint-xsmall) {
        font-size: 18px;
      }
  }
  .card{
    .thumbnail {
        overflow: hidden;
        position: relative;
        overflow: hidden;
        .button-padding {
            padding: 0;
        
            .img {
              width: 60%;
              border-radius: 40px;
              @media (max-width: $layout-breakpoint-xsmall) {
                  border-radius: 20px;
                }
              
            }
          }
    
      }

    
    
    .name{
        font-size: 20px;
        font-family: "Jost", sans-serif;
        padding: 10px 0;
        @media (max-width: $layout-breakpoint-xsmall) {
            font-size: 12px;
          }
    }
    .sub{
      color: $gray;
      padding: 5px 0;
      font-size: 20px;
      @media (max-width: $layout-breakpoint-xsmall) {
          font-size: 12px;
        }
    }
  }
}

.about-modal{
  position: absolute;
    left: 50%;
    top: 50%;
    border-radius: 50px;
    transform: translate(-50%, -50%);
    padding: 50px;
    width: 70%;
    background-image: url("./Images/about-modal.jpg");
    background-size: cover;
    @media (max-width: $layout-breakpoint-xsmall) {
        max-height: calc(100vh - 200px);
        overflow-y: auto;
        width: 90%;
        padding: 20px;
      }
            @media (max-width: $layout-breakpoint-small) {
              max-height: calc(100vh - 200px);
              overflow-y: auto;
              width: 90%;
            }
      .alignment{
        @media (max-width: $layout-breakpoint-xsmall) {
            text-align: center;
          }
        .img {
            width: 90%;
            -webkit-filter: drop-shadow(5px 5px 5px #222222);
            filter: drop-shadow(5px 5px 5px #222222);
          }
      }
    
            .close-button {
              float: right;
            }
        .modal-alignment {
          width: 100%;
          .name {
            font-size: 30px;
            padding: 40px 0 10px 0;
            @media (max-width: $layout-breakpoint-xsmall) {
              font-size: 18px;
              padding: 20px 0 10px 0;
          }
          }
    
          .sub {
            font-size: 20px;
            padding-top: 20px;
            @media (max-width: $layout-breakpoint-xsmall) {
                font-size: 18px;
              }
          }
    
          .sub-details {
            font-size: 20px;
            font-weight: 600;
            padding-top: 70px;
            padding-bottom: 30px;
            font-family: "Jost", sans-serif;
            @media (max-width: $layout-breakpoint-xsmall) {
                font-size: 12px;
              }
          }
    
          .details {
            font-size: 20px;
            font-family: "Jost", sans-serif;
            text-align: justify;
            @media (max-width: $layout-breakpoint-xsmall) {
                font-size: 12px;
              }
          }
        }

}

.modal-body {
  position: absolute;
  left: 50%;
  top: 50%;
  border-radius: 50px;
  transform: translate(-50%, -50%);
  padding: 50px;
  width: 70%;

  &.blue{
    background-image: url("./Images/blue_bg.jpg");
    background-size: cover;
    @media (max-width: $layout-breakpoint-xsmall) {
      background-position: center center;
      }
            @media (max-width: $layout-breakpoint-small) {
              background-position: center center;
            }
  }

  &.pink{
    background-image: url("./Images/pink_bg.jpg");
      background-size: cover;
      @media (max-width: $layout-breakpoint-xsmall) {
          background-position: center center;
        }
                @media (max-width: $layout-breakpoint-small) {
                  background-position: center center;
                }
  }
  @media (max-width: $layout-breakpoint-xsmall) {
      max-height: calc(100vh - 200px);
        padding: 50px 20px;
        width: 90%;
    }
        @media (max-width: $layout-breakpoint-small) {
          max-height: calc(100vh - 200px);
          width: 90%;
          overflow: auto;
        }

    .alignment{
      @media (max-width: $layout-breakpoint-xsmall) {
          text-align: center;
        }
                @media (max-width: $layout-breakpoint-small) {
                  text-align: center;
                }

      .img {
          width: 70%;
          -webkit-filter: drop-shadow(5px 5px 5px #222222);
          filter: drop-shadow(5px 5px 5px #222222);
      
          @media (max-width: $layout-breakpoint-xsmall) {
            width: 30%;
          }
                    @media (max-width: $layout-breakpoint-small) {
                      width: 40%;
                    }
        }
    }
  
            .close-button {
              float: right;
            }
  .modal-alignment{
    padding-top: 50px;
    width: 100%;
    @media (max-width: $layout-breakpoint-xsmall) {
        padding-top: 30px;
      }

      .name {
        font-size: 30px;
        @media (max-width: $layout-breakpoint-xsmall) {
            font-size: 12px;
          }
      }
      .sub {
        font-size: 20px;
        padding-top: 20px;
        @media (max-width: $layout-breakpoint-xsmall) {
            font-size: 12px;
            padding-top: 10px;
          }
      }
      .sub-details {
        font-size: 20px;
        font-weight: 600;
        padding-top: 70px;
        padding-bottom: 30px;
        font-family: "Jost", sans-serif;
        @media (max-width: $layout-breakpoint-xsmall) {
            font-size: 12px;
            padding-top: 10px;
            padding-bottom: 10px;
      }
                    @media (max-width: $layout-breakpoint-small) {
                      padding-top: 10px;
                    }
      }
      .details {
        font-size: 20px;
        font-family: "Jost", sans-serif;
        text-align: justify;
        @media (max-width: $layout-breakpoint-xsmall) {
            font-size: 12px;
            width: 100%;
          }
      }
  }
}

.questions{
  padding: 150px 0 150px 200px;
  text-align: center;
  font-family: "Jost", sans-serif;
  @media (max-width: $layout-breakpoint-xsmall) {
    padding: 40px 0 40px 10px;
  }
    @media (max-width: $layout-breakpoint-small) {
      padding: 40px 0 40px 10px;
    }
  .title {
      font-size: 50px;
      font-weight: 800;
      font-family: "Jost", sans-serif;
      padding-bottom: 50px;
      @media (max-width: $layout-breakpoint-xsmall) {
          scale: 0.5;
        }
    }
      .display{
        display: flex;
        align-items: center;
        height: 30vw;
        @media (max-width: $layout-breakpoint-xsmall) {
            height: auto;
          }
                    @media (max-width: $layout-breakpoint-small) {
                      height: auto;
                    }
        .accordion-body {
            border-radius: 10px !important;
            margin: 10px;
            text-align: left;
            color: $purple;
        
            .name {
              font-size: 20px;
              @media (max-width: $layout-breakpoint-xsmall) {
                  font-size: 12px;
                }
              
            }
        
            hr {
              border: 1px solid $purple;
            }
        
            .answer {
              font-size: 20px;
              font-weight: 600;
              @media (max-width: $layout-breakpoint-xsmall) {
                  font-size: 12px;
                }
            }
          }
      }
    .relative{
      position: relative;
      .alignment {
        position: sticky;
          text-align: right;
          overflow: hidden;
            position: relative;
            overflow: hidden;
            @media (max-width: $layout-breakpoint-xsmall) {
                text-align: center;
              }
                            @media (max-width: $layout-breakpoint-small) {
                              text-align: center;
                            }
          .img {
            width: 100%;
            text-align: center;
          }
        }
    }
}

.shop{
  padding: 100px 200px;
  // background-image: url("./Images/shop.jpg");
  background: rgba(236, 201, 195, .5);
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: $layout-breakpoint-xsmall) {
      padding: 40px 20px;
    }
        @media (max-width: $layout-breakpoint-small) {
          padding: 40px 20px;
        }
  .card{
      background: rgba(255, 255, 255, 0.2);
      margin-bottom: 20px;
      padding: 100px 100px;
      border-radius: 16px;
      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(5px);
      -webkit-backdrop-filter: blur(5px);
      border: 1px solid rgba(255, 255, 255, 0.3);
      hr{
        border: 1px solid $purple;
      }
            @media (max-width: $layout-breakpoint-xsmall) {
              padding: 40px 30px;
            }
                        @media (max-width: $layout-breakpoint-small) {
                          padding: 40px 30px;
                        }
      .title{
        font-size: 45px;
        @media (max-width: $layout-breakpoint-xsmall) {
            font-size: 18px;
          }
      }
      .sub{
        font-size: 20px;
        @media (max-width: $layout-breakpoint-xsmall) {
            font-size: 18px;
          }
      }
      .placement{
        padding-top: 25px;
        .flex{
          text-align: center;
          .nav{
            text-decoration: none;
            color: $purple;
            padding-left:30px;
          }
          .nav-username{
            text-decoration: none;
            color: $purple;
            font-size: 18px;
            @media (max-width: $layout-breakpoint-xsmall) {
                font-size: 12px;
              }
          }
          .logo {
              width: 70%;
              margin-right: 20px;
              filter: grayscale(100%);
              &:hover{
                filter: none;
                scale: 1.1;
              }

              &.small{
                width: 50%;
                @media (max-width: $layout-breakpoint-xsmall) {
                    width: 40%;
                  }
              }
              @media (max-width: $layout-breakpoint-xsmall) {
                width: 40%;
              }
                @media (max-width: $layout-breakpoint-xsmall) {
                        width: 40%;
                  }
            }
          @media (max-width: $layout-breakpoint-xsmall) {
            display: block;
          }

          }

        }
        
      }
}

.footer{
  padding: 50px 50px;
  @media (max-width: $layout-breakpoint-xsmall) {
      padding: 40px 20px;
    }
  .block{
    display: block;
    @media (max-width: $layout-breakpoint-xsmall) {
        padding: 0 0 30px 0 ;
      }
            @media (max-width: $layout-breakpoint-small) {
              padding: 0 0 30px 0;
            }
    .logo {
        width: 30%;
        @media (max-width: $layout-breakpoint-xsmall) {
            width: 50%;
          }
      }
    .footer-title{
      margin-top: 20px;
      width: 40%;
      font-size: 20px;
      @media (max-width: $layout-breakpoint-xsmall) {
          margin-top: 10px;
          width: 100%;
          font-size: 12px;
        }

    }

    .about{
      font-weight: 800;
      padding: 1px;
      text-transform: none;
      color: $purple;
      font-size: 20px;
      @media (max-width: $layout-breakpoint-xsmall) {
          font-size: 12px;
        }
    }
        .contact {
          font-weight: 600;
          font-size: 20px;
          cursor: pointer !important;
          padding-bottom: 10px;
          @media (max-width: $layout-breakpoint-xsmall) {
              font-size: 12px;
            }
        }
        .details{
          display: flex;
          align-items: center;
          .nav{
            text-decoration: none;
            color: $purple;
          }
        }
  }
  
}

.copyright{
  text-align: center;
  padding: 10px 0 10px 0;

}


